import React, { useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const NotificationBell = () => {
  const [notifications, setNotifications] = useState([
    // { id: 1, message: 'New message received', read: false },
  ]);

  // Count unread notifications
  const unreadCount = notifications.filter(notification => !notification.read).length;

  // Mark all as read
  const markAllAsRead = () => {
    setNotifications(notifications.map(notification => ({ ...notification, read: true })));
  };

  // Clear all notifications
  const clearNotifications = () => {
    setNotifications([]);
  };

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle nav className="nav-link-icon position-relative tv_dropdown">
        <span className="material-symbols-outlined" style={{ fontSize: "2rem", position: "relative" }}>
          notifications
          {unreadCount > 0 && (
            <span 
              className="badge bg-danger text-white rounded-circle position-absolute"
              style={{
                fontSize: "10px",
                minWidth: unreadCount > 9 ? "1.8rem" : "1.5rem", // Increase width for two digits
                height: "1.5rem",    
                lineHeight: "1.5rem", 
                textAlign: "center",
                top: "-0.4rem",   // Align the badge at the top of the bell icon
                right: "-0.7rem", // Move it more to the left
                display: "flex",  // Ensure text is centered
                justifyContent: "center",
                alignItems: "center",
                padding: "0.3em"
              }}
            >
              {unreadCount}
            </span>
          )}
        </span>
      </DropdownToggle>

      <DropdownMenu 
        style={{
          maxHeight: '300px', // Set the maximum height
          overflowY: 'auto'   // Enable vertical scrolling
        }}
      >
        <DropdownItem header>Notifications</DropdownItem>
        {notifications.length === 0 ? (
          <DropdownItem disabled>No new notifications</DropdownItem>
        ) : (
          <>
            {notifications.map(notification => (
              <DropdownItem key={notification.id} className={notification.read ? 'text-muted' : ''}>
                {notification.message}
              </DropdownItem>
            ))}
            <DropdownItem divider />
            <DropdownItem onClick={markAllAsRead}>Mark all as read</DropdownItem>
            <DropdownItem onClick={clearNotifications}>Clear all notifications</DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default NotificationBell;
